import React from "react";
import { ModalContext, ModalType } from "@src/components/modals/ModalController";
import * as S from "./styles";
import Container from "@src/components/Container";
import { Button, Space, Subheading, Title } from "@src/components/nessie-web";
import Translate from "@src/components/translation/Translate";
import MojoSunglasses from "@src/assets/images/mojo-sunglasses-mentors.svg";
import { ActivationRedirectionContext } from "@src/contexts/ActivationRedirectionContext";
import { Link, navigate } from "gatsby";
import { getRelativePath } from "@src/utils/routes";

type CTASectionProps = {
  user: string;
};

const CTASection: React.FC<CTASectionProps> = ({ user }) => {
  const modalContext = React.useContext(ModalContext);
  const redirectionContext = React.useContext(ActivationRedirectionContext);

  function openLoginModal() {
    redirectionContext.preventRedirection();
    modalContext.showModal(ModalType.TeacherLogin);
  }

  if (user && user === "notTeacher") return null;

  return (
    <S.CTASectionContainer>
      <Container>
        <S.CTASectionFlex>
          <S.CTASectionFlexChild>
            <img src={MojoSunglasses} alt="Mojo with sunglasses" css={{ marginBlock: 40 }} />
            <Title size={1}>
              <Translate path="directus.page_mentors.mentors_only_heading" />
            </Title>
            <Space size="s" />
            {user ? (
              <>
                <Subheading size={1}>
                  <Translate path="directus.page_mentors.mentors_only_logged_in" />
                </Subheading>
                <Space size="m" />
                <Button onClick={() => navigate(getRelativePath("/become-mentor"))}>
                  <Translate path="directus.page_mentors.cta_1_button_text" />
                </Button>
              </>
            ) : (
              <>
                <Subheading size={1}>
                  <Translate path="directus.page_mentors.mentors_only_logged_out" />
                </Subheading>
                <Space size="m" />
                <Button onClick={openLoginModal}>
                  <Translate path="directus.page_mentors.cta_2_text" />
                </Button>
                <Space size="m" />
                <Subheading size={1}>
                  <Translate path="directus.page_mentors.mentors_only_logged_out_2" />
                  <br />
                  <Link to="/become-mentor">
                    <Translate path="directus.page_mentors.cta_2_button_text" />
                  </Link>
                </Subheading>
              </>
            )}
          </S.CTASectionFlexChild>
        </S.CTASectionFlex>
      </Container>
    </S.CTASectionContainer>
  );
};

export default CTASection;
